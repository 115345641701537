import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import Clm2Img from "../../assets/Icons/mail.svg";
import Clm1Img from "../../assets/Icons/phone.svg";
import BackArrowIcon from "../../assets/Images/accordian.svg";
import BannerBgImage from "../../assets/Images/ArticleWhitePaper/whitepapers_banner.png";
import Button from "../../components/Button";
import Layout from "../../components/layouts/layout";
import DataService from "../../services/api/data_services";
import { BASE_SITE_URL } from "../../shared/constants";
import Box from "../../templates/Box";
import ImageBanner from "../../templates/ImageBanner";
import Megaview from "../../templates/MegaView";
import ogimage from "../../assets/Images/ogImages/whitepaper@2x.png";
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";

const WhitePaperList = () => {
  const [megaviewData, setMegaViewData] = useState([]);

  useEffect(() => {
    DataService.getData(
      "/admin/api/post?page=1&page_id=1&type=White Paper"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  /**
   * TwoColumnContactInfo
   */
  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Box component
   */

  const BoxData = {
    boxBtn1Width: "230px",
    boxBtn1Height: "46px",
    boxBtn1variant: "contained",
    boxBtn1color: "primary",
    boxBtn1label: "CAREER",
    boxBtn1Title: "Join our team",
    Clm2SubTitle: "careers@digiryte.com",
    boxBtn1SubTitle:
      "Here, you’re not “just” a developer or a designer! Take a step forward and join us!",
    boxBtn2Width: "230px",
    boxBtn2Height: "46px",
    boxBtn2variant: "contained",
    boxBtn2color: "primary",
    boxBtn2label: "GET IN TOUCH",
    boxBtn2Title: "Let’s talk",
    boxBtn2SubTitle: "We’d love to help with your product.",
    route1: "/contact-us",
    route2: "/contact-us",
  };

  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: "White Papers",
    subtitleclassname: "",
    bnrImgSubTitle: "All Resources",
    bnrImgClassName: "insightsbannerimg",
    servicesColumn1: {
      data: [
        {
          service: "Articles",
          link: "/insights/articles",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "White Papers",
          link: "/insights/white-papers",
        },
      ],
    },
    // servicesColumn3: {
    //   data: [
    //     {
    //       service: "Podcast",
    //       link: "/insights/podcast",
    //     },
    //   ],
    // },
  };

  return (
    <Layout
      seo={{
        title: "Digiryte| Insights | White Paper |",
        ogtitle: "Digiryte| Insights | White Paper |",
        description:
          "A collection of our best thinking on strategy, Software Consultation, Product Development and Innovation.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/insights/white-papers`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Insights"
          // color="black"
          // style={{fontSize:'24px',fontWeight:'bold'}}
          // label="Home"
          // variant={props.variant}
          routepath="/insights"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Insights
        </Typography>
      </div>

      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-30 body-container">
          <h2 className="title-header ttc">
            {" "}
            <span className="red-line3letter">Whi</span>te Papers
          </h2>
          <div className="mt-50"></div>
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )}
      {/* {  clientStoryData.has_more && <div style={{ width: "100%", textAlign: "center" }}>
      <div onMouseEnter={toggleHover} onMouseLeave={toggleHover} className="mt-60" role="presentation" onClick={() => setPage(page + 1)} style={{display: 'inline-block'}}>
           <Button
                 icon={
                  (
                     <img
                       className="effect-btn-svgicon"
                       src={!isHover ? RightArrow : RightArrowWhite}
                       alt="Load More"
                     />
                     
                   )
                 }
            customClassName="info-view-resize-btn"
            color="primary"
            variant="outlined"
            label="LOAD MORE"
            routepath="/white-paper-list"
            tip="LOAD MORE"
          />
        </div>
      </div>} */}
      <div className="mt-70"></div>
      <Box BoxData={BoxData} />
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default WhitePaperList;
